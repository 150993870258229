const actionTypes = {

    GET_POSTS: "GET_POSTS",
    GET_POSTS_LIMIT: "GET_POSTS_LIMIT",
    GET_POSTS_ADMIN: "GET_POSTS_ADMIN",
    GET_BOOKMARKED_POSTS: "GET_BOOKMARKED_POSTS",
    GET_NEW_POST: 'GET-NEW-POST',
    GET_ALL_POSTS: 'GET_ALL_POSTS',
    GET_POST_BY_ID: 'GET_POST_BY_ID',
    UPDATE_DATA: 'UPDATE_DATA',
    RESET_DATA: 'RESET_DATA',

    GET_POSTS_FILTER: 'GET_POSTS_FILTER',

    LOGIN: 'LOGIN',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',

    GET_CURRENT: 'GET_CURRENT',

    DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',
    DEPOSIT_FAIL: 'DEPOSIT_FAIL',

    HISTORY_SUCCESS: 'HISTORY_SUCCESS',
    HISTORY_FAIL: 'HISTORY_FAIL',
}

export default actionTypes