export const categories = [
    {
        Id: 'NCT',
        Name: 'Nhà cho thuê',
    },
    {
        Id: 'CTCH',
        Name: 'Căn hộ chung cư',
    },
    {
        Id: 'CTMB',
        Name: 'Cửa hàng, mặt bằng',
    },
    {
        Id: 'CTPT',
        Name: 'Cho thuê phòng trọ',
    }
]